<script setup lang="ts">
withDefaults(defineProps<{
  buttonClass?: string;
  initialHeight: number;
  readLessTranslationKey?: string;
  readMoreTranslationKey?: string;
}>(), { buttonClass: '',readLessTranslationKey: 'common.minimize',readMoreTranslationKey: 'common.readmore', })

const collapsed = ref<boolean>(true);
const textRef = ref<HTMLInputElement | null>(null);

const actionText = computed(() => (collapsed.value
  ? __props.readMoreTranslationKey
  : __props.readLessTranslationKey));

const { height: textHeight } = useElementSize(textRef);

const textIsOverLimit = computed(() => textHeight.value > __props.initialHeight);

const styles = computed(() => (collapsed.value
  ? { maxHeight: `${__props.initialHeight}px` }
  : {}));

const toggleReadingMode = () => {
  collapsed.value = !collapsed.value;
};
</script>

<template>
  <div
    class="relative overflow-hidden"
    :style="styles"
  >
    <div
      ref="textRef"
      class="text-xs"
    >
      <slot />
      <button
        v-if="textIsOverLimit"
        class="ml-1 rounded-lg bg-white/90 px-1.5 text-xs font-medium backdrop-blur-lg"
        :class="cn({
          'absolute bottom-0 right-0': collapsed
        }, buttonClass)"
        @click="toggleReadingMode"
        v-text="$t(actionText)"
      />
    </div>
  </div>
</template>
